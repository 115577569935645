<div  class="container my-5">
    <div  class="card">
        <div  class="card-body">
            <h2  class="card-title text-center mb-4">Privacy Policy</h2>
            <h5 class="text-muted mb-4"><b>As of September 18, 2024</b></h5>
            <h4 class="font-weight-bold">Introduction</h4>
            <p class="lead mb-4">Thank you for visiting Fincen Filing Agents LLC, the website of Fincen Filing
                Agents LLC, and its affiliates doing business under the tradename Fincen Filing Agents LLC (“Fincen
                Filing Agents LLC” “we” or “us”).</p>
            <p class="lead mb-4">To better protect your privacy, Fincen Filing Agents LLC wants our clients,
                partners, employees, applicants, and other relevant parties to know how we collect personal data, the
                types of personal data we collect, and how we use and share your personal data. By visiting this website
                (the “Site”), or providing your information to us, you are accepting the practices described herein.
                Note that this Privacy Policy only applies to personal data we collect (1) on our Site, (2) through our
                client and partner-facing applications and tools, (3) from communications or applications you send us,
                (4) when you interact with our advertising and applications on third-party websites and services, if
                they include links to this notice; and (5) through your contractual relationship with us, as applicable.
                This Privacy Policy does not apply to any other personal data.</p>
            <h4 class="font-weight-bold">Information We Collect</h4>
            <p class="lead mb-4">Fincen Filing Agents LLC collects information that may be used to identify,
                describe, or link to people who visit our Site or request information about our product or the products
                of third parties. We collect and process personal data mainly from our clients and partners while doing
                business with them, and we collect and receive personal data on this Site and through social networks.
                The personal data we process consists primarily of business contact information from our clients and
                partners, and information you and our clients provide when using our products or accessing our websites
                or other applications.</p>
            <p class="lead mb-4">We also collect and collate information (including some personal data) from
                third-party partners and public sources. We process personal information to provide our services to our
                clients, to fulfill our contractual obligations and to improve our products and offerings. For more
                information on our processing activities, please review the sections below.</p>
            <ol >
                <li >Personal data that we collect when you use the Site</li>
            </ol>
            <p class="lead mb-4">We collect several types of information and personal data, including data:</p>
            <ul >
                <li >that relates to an identified or identifiable individual, such as name, postal
                    address, email address, telephone number, IP or MAC address, etc. and related information, such as
                    the contents of an email (“personal data”);</li>
                <li >about your internet connection, the equipment you use to access our Site, and
                    usage details (e.g., statistics) regarding our products (“usage information”);</li>
                <li >about browsing history, search history, information regarding the client’s
                    interaction with a website, application, or advertisement; and</li>
                <li >inferences drawn from any of the information identified above, such as
                    information used to create a profile of client’s preferences.</li>
            </ul>
            <p class="lead mb-4">We collect this information and personal data:</p>
            <ul >
                <li >directly from you and your employees when you or they provide it to us;</li>
                <li >automatically as you or your employees navigate through the Site or use our
                    products. Information and personal data collected automatically may include usage information, IP
                    addresses, and information and personal data collected through cookies, collected information from
                    your use of our Site, including search queries, surveys and content clicks, web beacons, and other
                    tracking technologies;</li>
                <li >from public and third-party sources. Third-party advertisers or their
                    advertising servers, or other third-party service providers may also place cookies on your devices
                    used to access our Site.</li>
            </ul>
            <p class="lead mb-4">Do Not Track (“DNT”) is a privacy preference that users are often able to set in
                their web browsers. When a user turns on DNT, the browser sends a message to websites requesting that
                they do not track the user. Please be advised that Fincen Filing Agents LLC does not change its
                practices, described elsewhere in this Privacy Policy, in response to Do Not Track browser settings or
                signals. For information about DNT, visit&nbsp;<a 
                    href="https://www.allaboutdnt.com/">www.allaboutdnt.com</a>.</p>
            <p class="lead mb-4">Third parties may collect personal information about your online activities over
                time and across different websites, including your use of this Site. This Privacy Policy does not apply
                to, and we are not responsible for, the practices of third parties that collect your personal
                information.</p>
            <ol >
                <li >Personal data you provide to us</li>
            </ol>
            <p class="lead mb-4">You may provide personal data to Us through your use of our Site or services, or
                when you apply for employment with us. The personal data we collect may include:</p>
            <ul >
                <li >personal data that you provide by conducting business with us, by filling in
                    forms on our Site or anywhere else we conduct business, by subscribing to newsletters or other
                    communications, or by corresponding with us by phone, e-mail or otherwise using our contact details.
                    This includes personal data provided at the time of requesting information or services, soliciting
                    business, or performing a service. We may also ask you for personal data when you enter a contract
                    with us, or a contest or promotion sponsored by us, and when you report a problem;</li>
                <li >identifiers, including name, address, unique identifiers, account name, and
                    job title;</li>
                <li >records and copies of your correspondence (including email addresses);</li>
                <li >your responses to surveys that we might ask you to complete for research
                    purposes;</li>
                <li >your search queries on the Site;</li>
                <li >personal data you provide during the contractual relationship for execution of
                    the partner or client contract;</li>
                <li >personal data you provide by using our incident management and project
                    management tools and applications;</li>
                <li >personal data you provide by communicating with our client care teams for
                    error resolving issues, training and support;</li>
                <li >your usage of our products and related tools and applications.</li>
            </ul>
            <p class="lead mb-4">Typically, the personal data you give us may include name, business affiliation,
                business address, telephone number, and email address, and any personal details required to conduct
                business with you or to resolve any inquiries or complaints.</p>
            <p class="lead mb-4">We will indicate to you when personal data is required to enter into a contract
                with you (such as in anticipation of a services agreement) or to perform a contract with you (such as to
                provide services at your request), and when failure to provide any personal data may result in our
                inability to provide requested services or products.</p>
            <p class="lead mb-4">For job applicants, we may collect various types of personal data about you for the
                purposes described in this Privacy Policy, including:</p>
            <ul >
                <li >data about you: including but not limited to your forename, surname, gender,
                    nationality, date of birth, home contact details (e.g., address, telephone number, e-mail),
                    immigration and eligibility to work data;</li>
                <li >data about your employment history and qualifications: qualifications,
                    references, CV and application form, background checks and verification of information from LinkedIn
                    or similar social media profile;</li>
                <li >data about your application: such as interview notes or assessment results;
                    and</li>
                <li >legal and criminal records data: legal records data are processed only in
                    compliance with the applicable laws and in specific circumstances (e.g., background checks for
                    senior roles or roles handling sensitive information).</li>
            </ul>
            <p class="lead mb-4">You may give us the personal data described above during the recruitment process,
                whether through the application forms on our online job portal, on the telephone or at interviews. We
                partner with certain third-party job sites, such as LinkedIn, and may receive your personal data via
                these organizations.</p>
            <ol >
                <li >Personal data we collect through automatic data collection technologies</li>
            </ol>
            <p class="lead mb-4">If you navigate through and interact with our Site and some of our products, we may
                use automatic data collection technologies to collect certain usage information about your equipment,
                browsing actions, and patterns. Automatic data collection is carried out using cookies and related
                technologies.</p>
            <ol >
                <li >Personal data we collect from public sources and third parties</li>
            </ol>
            <p class="lead mb-4">In order to provide our content services to our clients, we rely on content
                provided by third-party partners, as well as content that is made available to the public. Some of that
                content includes general information about names of directors, registered board members, managers, fund
                managers and other official persons of companies published on public accessible websites, letterheads,
                prospects, annual financial statements, invitations to shareholder meetings etc.</p>
            <p class="lead mb-4">During the job recruitment process, we may also receive personal data about you
                from other third parties; for example, recruiters; references from a previous employer; business
                partners; background check providers; as well as from publicly available sources such as social media.
            </p>
            <h4 class="font-weight-bold">Lawful Grounds for Processing</h4>
            <p class="lead mb-4">All processing and use of your personal data is justified by a “lawful ground” for
                processing. In most cases, processing will be justified on the lawful ground that:</p>
            <ul >
                <li >the processing is necessary to perform a contract with you or take steps to
                    enter into a contract at your request, such as to negotiate a contract with us, to fill an order, or
                    to provide product information that you have requested;</li>
                <li >the processing is necessary for us to comply with a relevant legal obligation,
                    such as keeping accounting records;</li>
                <li >the processing is in our legitimate interests, which are not overridden by the
                    interests and rights of the relevant individual whose personal data is collected; and</li>
                <li >you have consented to the processing.</li>
            </ul>
            <p class="lead mb-4">Where we rely on our legitimate interests to process personal data, those interests
                are:</p>
            <ul >
                <li >to use partner, client and website user data to conduct and develop our
                    business activities and improve our services in order to grow our business and profitability, while
                    limiting the use of this personal data to purposes that support the conduct and the development of
                    our business and the improvement of our services;</li>
                <li >to use third-party partner and publicly available content (which in some
                    instances includes personal data) to provide our content services to clients for their business
                    purposes, which may include, but not be limited to taking investment decisions or compliance with
                    applicable laws and regulations such as insider trading regulations and anti-bribery legislation,
                    but which are for our clients to determine and explain in their privacy notices.</li>
            </ul>
            <h4 class="font-weight-bold">How We Use Your Personal Data</h4>
            <p class="lead mb-4">The following is an overview of our purposes for using your personal data.
                Additional details on how we process your personal data may be provided to you in a separate notice or
                contract.</p>
            <h4 class="font-weight-bold">We use the personal data we collect to:</h4>
            <ul >
                <li >provide client services, including products and services; to take, verify,
                    process, and deliver orders and returns; to process payments, technical support, or other similar
                    purposes; and to establish and maintain client accounts;</li>
                <li >address your inquiries associated with the information you provide to us;</li>
                <li >provide training and after-sales-support on our products and answer to and
                    perform change requests;</li>
                <li >organize and perform incident management processes;</li>
                <li >implement projects by working together with clients and their users as part of
                    the project team;</li>
                <li >communicate with clients and partners, including responding to requests for
                    assistance and to update them about the status of their orders by postal mail, email, telephone, or
                    text message;</li>
                <li >investigate, prevent or take action regarding unauthorized use of our
                    services, illegal activities, suspected fraud or situations involving potential threats;</li>
                <li >provide you with underlying tools and applications helping to implement
                    projects and resolve incidents;</li>
                <li >discuss, carry out and implement change request and further developments of
                    our products;</li>
                <li >provide our content services to our clients and to report and administer
                    clients’ users’ usage of third-party information products (e.g., real-time data from stock exchanges
                    etc.);</li>
                <li >administer and manage performance of purchase or sales agreements with our
                    partners and clients;</li>
                <li >to enhance the experience for our users, we use notes relating to support
                    provided to specific employees, advisors or agents of clients in relation to our products and
                    services for their future use of our services;</li>
                <li >service your account;</li>
                <li >to the extent it is lawful to do so, market and promote our products including
                    through email or equivalent electronic means, to send news and newsletters, special offers and
                    promotions, or to otherwise contact clients about products, services or information;</li>
                <li >process, evaluate and complete certain transactions involving the Site, and
                    more generally transactions involving Fincen Filing Agents LLC products and services;</li>
                <li >operate, evaluate, maintain, improve and develop the Site (including
                    monitoring and analyzing trends, tracking user behavior, access to, and, where lawful to do so, use
                    of the Site for advertising and marketing);</li>
                <li >to the extent it is lawful to do so, engage with you about events, promotions,
                    the Site and Fincen Filing Agents LLC products and services and send you emails, event
                    notifications, invitations, newsletters, announcements, and other communications regarding matters
                    that may be of interest to you;</li>
                <li >facilitate your job application process;</li>
                <li >provide you with documentation or communications which you have requested, or
                    which is part of a product;</li>
                <li >correspond with users to resolve their queries or complaints;</li>
                <li >protect the security of the Site, Fincen Filing Agents LLC confidential and
                    proprietary information (which may include personal data), and Fincen Filing Agents LLC employees;
                </li>
                <li >manage, protect against and investigate fraud, risk exposure, claims and other
                    liabilities, including but not limited to violation of our contract terms or laws or regulations;
                </li>
                <li >share your personal data with third parties in connection with potential or
                    actual sale of our company or any of our assets, or those of any affiliated company, in which case
                    personal data held by us about our users may be one of the transferred assets.</li>
            </ul>
            <p class="lead mb-4">Fincen Filing Agents LLC will not sell or rent your personal data you provided to
                us to third parties for their own use; however, we may provide third parties with your personal data
                where there is a legal requirement to do so or where we need a third party to fulfill our services.</p>
            <h4 class="font-weight-bold">Sharing and Disclosure of Your Personal Data</h4>
            <p class="lead mb-4">When you have provided personal data to us, we may disclose personal data to other
                companies that we have engaged to assist us in providing you goods or services. This may include the
                following service providers:</p>
            <ul >
                <li >fulfillment houses;</li>
                <li >billing services;</li>
                <li >transaction managers;</li>
                <li >credit verification services;</li>
                <li >market researchers; and</li>
                <li >other third-party service providers.</li>
            </ul>
            <p class="lead mb-4">We do not sell or rent personal data to third parties for their own use but may
                provide certain usage information to advertisers to help provide you with relevant advertising where we
                are allowed to do so. Usage information, as used in this policy, is anonymized and aggregated data,
                which does not identify you in the way Personal Data would.</p>
            <p class="lead mb-4">We may also disclose any of your personal data or usage information to law
                enforcement or other appropriate third parties in connection with criminal investigations, investigation
                of fraud, infringement of intellectual property rights, or other suspected illegal activities, as may be
                required by applicable law, or, as we may deem necessary in our sole discretion, to protect our
                legitimate legal and business interests.</p>
            <p class="lead mb-4">We may also share your personal data in connection with the recruitment or
                employment application process, including sharing that data with:</p>
            <ul >
                <li >Fincen Filing Agents LLC affiliates;</li>
                <li >recruiters;</li>
                <li >IT service providers;</li>
                <li >companies providing Fincen Filing Agents LLC background check services; and
                </li>
                <li >payroll systems.</li>
            </ul>
            <p class="lead mb-4">If your application for employment with Fincen Filing Agents LLC is successful, you
                will be provided with a separate notice during your on-boarding process, which will provide you with
                additional information.</p>
            <p class="lead mb-4">We reserve the right to disclose, share, or otherwise transfer personal data and
                usage information in connection with a proposed or actual corporate merger, acquisition, consolidation,
                the sale of a portion of our business or the sale of substantially all of our assets, or other
                fundamental corporate change, whatever form it may take. In the event that there is a change of
                ownership or control of your personal data or usage information, we will post a prominent notice to that
                effect on our Site.</p>
            <h4 class="font-weight-bold">Transferring Personal Data</h4>
            <p class="lead mb-4">Fincen Filing Agents LLC is a global organization headquartered in the United
                States, and we may need to transfer your personal data to third-party partners and to our affiliates for
                the purposes described above. If your personal data is subject to the GDPR and is transferred outside
                the EU to other Fincen Filing Agents LLC affiliates or to third-party service providers, we will take
                steps to ensure that your personal data receives the same level of protection as if it remained within
                the EU, including by entering into data transfer agreements using the European Commission approved
                Standard Contractual Clauses, or by relying on certification schemes. For transfers of personal data
                among Fincen Filing Agents LLC affiliates we have put in place European Commission-approved Standard
                Contractual Clauses and have entered into an Intragroup data transfer agreement. You have a right to
                obtain details of the mechanism under which your personal data is transferred outside of the EU,
                although please note that we may need to redact copies of transfer agreements for reasons of commercial
                confidentiality, provided that you can retain access to the relevant safeguards.</p>
            <p class="lead mb-4">With respect to the information you provide to us through the recruitment or
                employment process, we may share and transfer your data with other members of the Fincen Filing Agents
                LLC Group located globally (including in countries outside EU/EEA, such as the United States of America,
                India and the Philippines). Fincen Filing Agents LLC has adopted an Intra-Group Data Transfer Agreement
                to ensure your personal data receives an adequate level of protection wherever it is processed within
                the Fincen Filing Agents LLC group.</p>
            <p class="lead mb-4">Fincen Filing Agents LLC reserves the right to share any information that you
                provide which is not deemed personal data or is not otherwise subject to contractual restrictions.</p>
            <h4 class="font-weight-bold">Retention of Your Personal Data</h4>
            <p class="lead mb-4">We keep personal data only for as long as required to fulfill the purposes for
                which it was collected. In general, we retain personal data for a period of time corresponding to a
                statute of limitation, for example to maintain an accurate record of client and partner dealings.
                However, in some circumstances we may retain personal data for other periods of time, for instance where
                we are required to do so in accordance with legal, tax or accounting requirements, or if required to do
                so by a legal process, legal authority, or other governmental entity having authority to make the
                request, for so long as required in accordance with our document retention policy.</p>
            <p class="lead mb-4">With respect to personal data we obtain from job applicants through the recruitment
                process, our general approach is to retain your application for a period of 6 months or, where you
                consent to a longer period of retention, for up to 2 years following the closure of the recruitment
                process for the role you applied for. Otherwise, you should note that, in certain cases, legal or
                regulatory obligations require us to retain specific records for a set period of time. If you accept
                employment with Fincen Filing Agents LLC, your personal data will be held on the basis set out in our
                Employee Data Privacy Notice, which will be shared with you following your hiring.</p>
            <h4 class="font-weight-bold">Your Rights</h4>
            <p class="lead mb-4">You may have certain rights in connection with the personal data we collect from
                you or that you provide to us. Depending on where you reside, these rights may be specifically provided
                to you by statute. We will take steps in accordance with applicable legislation to keep personal data
                accurate, complete, and up to date.</p>
            <p class="lead mb-4">Specifically, you may be entitled to exercise the below rights in connection with
                your personal data:</p>
            <ul >
                <li >request that Fincen Filing Agents LLC disclose our collection and use of your
                    personal data during the past 12 months;</li>
                <li >request that we provide your personal data to you in structured, commonly used
                    and machine-readable format;</li>
                <li >request that we correct any inadequate, incomplete, or incorrect personal data
                    we hold;</li>
                <li >request that we delete your personal data;</li>
                <li >request that we restrict processing of your personal data;</li>
                <li >object to processing or withdraw your consent for processing (including
                    profiling) based on legitimate interest lawful grounds, without affecting the lawfulness of
                    processing before withdrawal;</li>
                <li >object to direct marketing (including profiling); or</li>
                <li >file a complaint with the applicable supervisory authority.</li>
            </ul>
            <p class="lead mb-4">Additionally, job applicants specifically have the following rights with respect to
                personal data:</p>
            <ul >
                <li >withdraw your consent at any time; for example, if you no longer want us to
                    retain your unsuccessful application for an extended period of up to 2 years;</li>
                <li >request that we restrict our processing of your personal data where: <ul
                        >
                        <li >you contest the accuracy of the personal data until we have taken
                            sufficient steps to correct or verify its accuracy;</li>
                        <li >where the processing is unlawful, but you do not want us to erase the
                            personal data;</li>
                        <li >where we no longer need your personal data for the purposes of the
                            processing, but you require such personal data for the establishment, exercise, or defense
                            of legal claims; or</li>
                        <li >where you have objected to processing justified on legitimate interest
                            grounds (see below) pending verification as to whether Fincen Filing Agents LLC has
                            compelling legitimate grounds to continue processing.</li>
                    </ul>
                </li>
            </ul>
            <p class="lead mb-4">We will not discriminate against you in any way if you elect to exercise any of the
                above rights. Please contact us as indicated in the How To Contact Us section below or using the
                email-address:&nbsp;xe@edgaragents.com&nbsp;if you desire to exercise any of your rights, or if you have
                any questions or complaints regarding the processing of your personal data.</p>
            <p class="lead mb-4">We may need to request additional information from you to respond to your request
                to exercise any of the above rights. We will respond to your verifiable request and to take action with
                respect to your request within 45 days. If we require additional time to disclose and or delete your
                personal information as provided under applicable law, we will inform you within 45 days of your
                request.</p>
            <h4 class="font-weight-bold">To the extent allowed under applicable law, Fincen Filing Agents LLC</h4>
            <p class="lead mb-4">may decline to comply with certain data deletion requests in the event that your
                personal data is required to:</p>
            <ul >
                <li >complete the transaction for which the personal information was collected,
                    provide a good or service requested by you, or reasonably anticipated within the context of a
                    business’s ongoing business relationship with you, or otherwise perform a contract between Fincen
                    Filing Agents LLC and you;</li>
                <li >detect security incidents, protect against malicious deceptive, fraudulent, or
                    illegal activity; or prosecute those responsible for that activity;</li>
                <li >debug to identify and repair errors that impair existing intended
                    functionality;</li>
                <li >exercise free speech, ensure the right of another client to exercise his or
                    her right of free speech, or exercise another right provided for by law;</li>
                <li >comply with applicable laws or legal obligations;</li>
                <li >engage in public or peer-reviewed scientific, historical, or statistical
                    research in the public interest that adheres to all other applicable ethics and privacy laws, when
                    our deletion of that information is likely to render impossible or seriously impair the achievement
                    to that research, if you have provided informed consent;</li>
                <li >enable solely internal uses that are reasonably aligned with your expectations
                    based on your relationship with us; or</li>
                <li >otherwise use your personal information, internally, in a lawful manner that
                    is compatible with the context in which you provided information.</li>
            </ul>
            <h4 class="font-weight-bold">Children</h4>
            <p class="lead mb-4">This Site is not intended or directed to anyone under the age of 16 and we do not
                provide services to anyone who we know to be under the age of 18, nor do we collect any personal
                information from anyone who we know to be under the age of 18. If you are under the age of 16, you
                should use this Site only with the involvement of a parent or guardian and should not submit any
                personal information to us.</p>
            <h4 class="font-weight-bold">Data Security</h4>
            <p class="lead mb-4">Fincen Filing Agents LLC takes reasonable precautions to protect personal data from
                loss, misuse, unauthorized access, disclosure, altercation, and destruction. Unfortunately, while we
                work hard to ensure the security of your personal data while it is on our systems, no security measures
                are perfect, and data transmitted over the Internet is not guaranteed to be 100% secure. We cannot and
                do not ensure or warrant the security of any personal data you transmit, and you do so at your own risk.
            </p>
            <p class="lead mb-4">Where we have given you (or where you have chosen) a password to access certain
                parts of our jobsites, you are responsible for keeping this password confidential. We request that you
                do not share a password with anyone. You are responsible for the confidentiality of the password related
                to your account.</p>
            <h4 class="font-weight-bold">Links to Other Websites</h4>
            <p class="lead mb-4">Our Site may provide links to other websites for your convenience and information.
                These websites may operate independently from Fincen Filing Agents LLC. Linked websites may have their
                own privacy notices or policies, which we strongly suggest you review if you visit any linked websites.
                To the extent any linked websites you visit are not owned or controlled by Fincen Filing Agents LLC, we
                are not responsible for the websites’ content, any use of the websites, or the privacy practices of the
                websites.</p>
            <h4 class="font-weight-bold">Changes to Our Privacy Policy</h4>
            <p class="lead mb-4">This Privacy Policy may be amended from time to time. Please review it
                periodically. Changes to this Policy will be identified by the date at the top of this page.</p>
            <h4 class="font-weight-bold">How to Contact Us</h4>
            <p class="lead mb-4">If you have any questions or comments about this Privacy Policy, Fincen Filing
                Agents LLC privacy practices or if you would like us to exercise any of your data subject rights, please
                contact us as provided below:</p>
            <p class="lead mb-4">Legal Department<br > 207 W 25th Street, 9th Floor New York NY
                10001</p>
            <p class="lead mb-4"><strong >Phone:</strong>&nbsp;(212) 265-3347</p>
            <p class="lead mb-4"><strong >Email:</strong>&nbsp;info@fincen-filing-agents.com
            </p>
        </div>
    </div>
</div>