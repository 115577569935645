export const API_METHOD = {
  get: "get",
  post: "post",
  put: "put",
  delete: "delete",
  patch: "patch",
};
export const ORDER = {
  asc: "asc",
  desc: "desc",
};
export const DITA_TYPE = {
  folder: "folder",
  resource: "resource",
};
export const STRING_BOOLEAN = {
  yes: "yes",
  no: "no",
  cancel: "cancel",
};
export const CALENDAR_MIN_YEAR = {
  minDocPeriodFilter: 2000,
};
export const ALLOWED_EXTENSION = {
  clientLogo: ["jpeg", "png", "jpg"],
};
export const MAX_CLIENT_LOGO_FILE_SIZE = 2097152;
export const PER_PAGE_OPTION = [
  { label: "10", size: 10 },
  { label: "30", size: 30 },
  { label: "50", size: 50 },
  { label: "100", size: 100 },
  { label: "500", size: 500 },
];
export const DEFAULT_PAGE_SIZE = 10;
export const DOCUMENT_RENDER_STATUS_INTERVAL = 2000;
export const DOCUMENT_RENDER_MAX_TIME = 30;
export const ROLLFORWARD_FORM_TYPES = [
  "487",
  "24F 2NT",
  "PRE 14A",
  "DEF 14A",
  "DEFA14A",
  "485BPOS",
  "497",
  "497K",
  "485APOS",
  "485BXT",
  "N-CSR",
  "N-CSRS",
  "N-1A",
  "24F-2NT",
  "497J",
  "S-6",
  "424B2",
  "FWP",
  "424B3",
  "PROSPECTUS",
  "ABS-15G",
  "ABS-15G/A",
  "SC 13D",
  "SC 13D/A",
  "SC 13G",
  "SC 13G/A",
  "ARS",
];
export const ROLLFORWARD_XBRL_FORM_TYPES = ["8-K", "8-K/A"];
export const showAllCompany = "Show all companies";
export const wordMLAllowedFiles = ["doc", "docx"];
export const contentAllowedFiles = ["zip"];
export const DOCUMENTS_LIMIT = 10;
export const xmlAllowedFiles = ["xml"];

export const FILTER_TYPE = [
  { value: "1", label: "Day" },
  { value: "2", label: "Week" },
  { value: "3", label: "Month" },
  { value: "4", label: "Custom Range"},
]; 

export const USERAUDITLOG_ACTION =[
  {id : 1, name : "Create"},
  {id : 2, name : "Update"},
 // {id : 3, name : "Delete"},
];
export const COMPANY_AUDITLOG_TYPE =[ 
  {id : 1, name : "Create"},
  {id : 2, name : "Update"},
  {id : 3, name : "Delete"},
]
export const AUDITLOG_ACTION = [
  {id : 1, name : "Create"},
  {id : 2, name : "Update"},
  {id : 3, name : "Delete"},
  {id : 4, name : "New Version Upload"},
  {id : 5, name : "Read"},
  {id : 6, name : "Submit"},
  {id : 7, name : "Revoke"},
  {id : 8, name : "Comments"},
];



export const EVOX_AUDITLOG_ACTION =[
  {id : 1, name : "Create"},
  {id : 2, name : "Update"},
  {id : 3, name : "Delete"},
  {id : 5, name : "Read"},
];

export const BOIR_Address_COUNTRY = [ 1, 48, 150 ];
export const MODULE_LIST = [
  {
    name: "Dashboard",
    display: false,
    svgPath: " fe_assets/svg/dashboard[@].svg",
    show: true,
    routerLink: "/dashboard"
  },
  {
    name: "Channel",
    display: false,
    svgPath: "fe_assets/svg/channel[@].svg",
    show: true,
    children: [
      {
        name: "Channels",
        show: true,
        routerLink: "/client",
      },
      {
        name: "Add Channel",
        show: true,
        routerLink: "/client/add",
      },
    ],
  },
  // {
  //   name: "Company",
  //   display: false,
  //   svgPath: "fe_assets/svg/company[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "List Companies",
  //       show: true,
  //       routerLink: "/companies",
  //     },
  //     {
  //       name: "Add Company",
  //       show: true,
  //       routerLink: "/companies/add"
  //     },
  //     {
  //       name: "Audit Log",
  //       show: true,
  //       routerLink: "/companies/auditlog"
  //     },
  //     // {
  //     //   name: "Deleted Companies",
  //     //   show: true,
  //     //   routerLink: "/companies/deleted-companies"
  //     // }
  //   ]
  // },
  // {
  //   name: "Library",
  //   display: false,
  //   svgPath: "fe_assets/svg/library[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "Content",
  //       show: true,
  //       routerLink: "/library",
  //     },
  //     {
  //       name: "Recent Documents",
  //       show: true,
  //       routerLink: "/library/recent-documents",
  //     },
  //   ],
  // },
  // {
  //   name: "History",
  //   display: false,
  //   svgPath: " fe_assets/svg/history[@].svg",
  //   show: true,
  //   routerLink: "/history"
  // },
  {
    name: "User",
    display: false,
    svgPath: "fe_assets/svg/user[@].svg",
    show: true,
    children: [
      {
        name: "Users",
        show: true,
        routerLink: "/users",
      },
      {
        name: "Add User",
        show: true,
        routerLink: "/users/add"
      },
      {
        name: "Audit Log",
        show: true,
        routerLink: "/users/auditlog"
      }
    ]
  },
  // {
  //   name: "System Manager",
  //   display: false,
  //   svgPath: "fe_assets/svg/system_manager[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "Feature Role Mapping",
  //       show: true,
  //       routerLink: "/role-management/feature-mapping",
  //     },
  //     {
  //       name: "Query",
  //       show: true,
  //       routerLink: "/system/sql-query",
  //     },
  //   ],
  // },
  // {
  //   name: "XE Compare",
  //   display: false,
  //   svgPath: "fe_assets/svg/xe_compare[@].svg",
  //   show: true,
  //   routerLink: "/xe-compare",
  // },
  // {
  //   name: "EA16",
  //   display: false,
  //   svgPath: " fe_assets/svg/EA16[@].svg",
  //   show: true,
  //   routerLink: "/marketforms",
  // },
  // {
  //   name: "EdgarEye",
  //   display: false,
  //   svgPath: "fe_assets/svg/edgar_eye[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "Search Filings",
  //       show: true,
  //       routerLink: "/edgar-eye/search-filings",
  //     },
  //     {
  //       name: "Today Filings",
  //       show: true,
  //       routerLink: "/edgar-eye/today",
  //     },
  //     {
  //       name: "My Alerts",
  //       show: true,
  //       routerLink: "/edgar-eye/my-alerts",
  //     },
  //   ],
  // },
  // {
  //   name: "EAsyView",
  //   display: false,
  //   svgPath: "fe_assets/svg/easy_view[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "List Companies",
  //       show: true,
  //       routerLink: "/easyview/easyviewcompanies",
  //     },
  //     {
  //       name: "List Projects",
  //       show: true,
  //       routerLink: "/easyview/projectlist",
  //     },  
  //     {
  //       name: "Taxonomy Search",
  //       show: true,
  //       routerLink: "/easyview/taxonomy"
  //     },
  //     {
  //       name: "Audit Log",
  //       show: true,
  //       routerLink: "/easyview/auditlog"
  //     },
  //   ]
  // },
  // {
  //   name: "Validator",
  //   display: false,
  //   svgPath: "fe_assets/svg/Validator[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "Companies",
  //       show: true,
  //       routerLink: "/validator/validatorcompanies",
  //     },
  //     {
  //       name: "Projects",
  //       show: true,
  //       routerLink: "/validator/projectlist",
  //     },
  //     {
  //       name: "Taxonomy Search",
  //       show: true,
  //       routerLink: "/validator/taxonomy",
  //     },
  //   ],
  // },
  // {
  //   name: "iMergiX",
  //   display: false,
  //   svgPath: "fe_assets/svg/imergix[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "List Companies",
  //       show: true,
  //       routerLink: "/imergix",
  //     },
  //     {
  //       name: "Taxonomy Search",
  //       show: true,
  //       routerLink: "/imergix/taxonomy",
  //     },
  //   ],
  // },
  // {
  //   name: "eVox",
  //   display: false,
  //   svgPath: "fe_assets/svg/evox[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "List Companies",
  //       show: true,
  //       routerLink: "/eVox",
  //     },
  //     {
  //       name: "Analytics",
  //       show: true,
  //       routerLink: "/eVox/evox-analytics"
  //     },
  //     {
  //       name: "Audit Log",
  //       show: true,
  //       routerLink: "/eVox/auditlog"
  //     },
  //   ]
  // },
  // {
  //   name: "EA Connect",
  //   display: false,
  //   svgPath: "fe_assets/svg/EA_TSR[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "List Companies",
  //       show: true,
  //       routerLink: "/EA-Connect"
  //     },
  //     {
  //       name: "Stitching",
  //       show: true,
  //       routerLink: "/EA-Connect/stitching"
  //     },
  //     {
  //       name: "TSR",
  //       show: true,
  //       routerLink: "/TSR/company-list"
  //     }
  //   ]
  // },
  // {
  //   name: "EA Forms",
  //   display: false,
  //   svgPath: "fe_assets/svg/EA Forms[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "Form D",
  //       show: true,
  //       routerLink: "/EA-Forms",
  //     },
  //     // {
  //     //   name: "Filing Reports",
  //     //   show: true,
  //     //   routerLink: "/EA-Forms/file-counter",
  //     // },
  //   ],
  // },
  {
    name: "BOIR",
    display: false,
    svgPath: "fe_assets/svg/BOIR[@].svg",
    show: true,
    children: [
      {
        name: "My Companies",
        show: true,
        routerLink: "/BOIR",
      },
      {
        name: "Individuals",
        show: true,
        routerLink: "/BOIR/entitylist",
      },
      {
        name: "Transactions",
        show: true,
        routerLink: "/BOIR/Boir-Dashboard",
      }
    ],
  },
  {
    name: "User Guide",
    display: false,
    svgPath: "fe_assets/svg/userGuide[@].svg",
    show: true,
    routerLink: "/user-guide/view",
  },
  {
    name: "Replace User Guide",
    display: false,
    svgPath: "fe_assets/svg/userGuide[@].svg",
    show: true,
    routerLink: "/replace-user-guide/add",
  }


  // {
  //   name: "EA 40",
  //   display: false,
  //   svgPath: "fe_assets/svg/Webhook_icon[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "List Companies",
  //       show: true,
  //       routerLink: "/EA-40",
  //     },
  //   ]
  // }, 
  // {
  //   name: "BOIR",
  //   display: false,
  //   svgPath: "fe_assets/svg/EA Forms[@].svg",
  //   show: true,
  //   children: [
  //     {
  //       name: "List Companies",
  //       show: true,
  //       routerLink: "/BOIR",
  //     },
  //   ],
  // }, 
];
