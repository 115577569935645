import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanLoad,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  Route,
  UrlSegment,
} from "@angular/router";
import { Observable } from "rxjs";
import { ConfigService } from "../services";
import { HTTP_STATUS_CODE } from "../constants/http-status.constant";
import { AuthService } from "src/app/shared/services/auth.service";
import { Location } from "@angular/common";
import { ROUTE_PATHS } from "../constants";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor(
    private readonly configService: ConfigService,
    private readonly router: Router,
    public authService: AuthService,
    private location: Location,
    private cookieService : CookieService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isLoggedIn()) {
      return this.authService.isLoggedIn();
    } else {
      let userConfigList: any = JSON.stringify(
        // localStorage.getItem("userConfigList")
        this.cookieService.get("userConfigList")
      );
      if (userConfigList) {
        this.authService.setLoggedInStatus(true);
        this.configService.setUserConfig(userConfigList);
        return true;
      } else {
        let baseUrl = localStorage.getItem("baseUrl");
        if (baseUrl) {
          this.router.navigate([baseUrl]);
        } else {
          this.router.navigate(["/login"]);
        }
        return false;
      }
    }
    // if (!this.configService.currentUserConfig()) {
    //   // this.configService.getUserConfiguration().subscribe(
    //   //   (userConfig) => {
    //   //     this.configService.setUserConfig(userConfig);
    //   //   },
    //   //   (error) => {
    //   //     if (error.status === HTTP_STATUS_CODE.unAuthorized) {
    //   //       window.location.href = '/sso';
    //   //     }
    //   //   },
    //   // );
    // }
    // return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    let userId: any =
    localStorage.getItem("userDetailsData") || localStorage.getItem("userId");
       let userInfo: string = this.cookieService.get("userConfigList");
    // let userInfo: string = localStorage.getItem("userConfigList");
    let baseUrl:string  = localStorage.getItem("baseUrl") || ROUTE_PATHS.xe_login;
    let isAdmin:any = localStorage.getItem('Admin') || localStorage.getItem("Admin");
    try {
      let parsedData: any = JSON.parse(userInfo);
      let path = route.path;
      if(parsedData && parsedData.MODULE_ACCESS){
        if(userId == 1 ){
          return true;
        }else if(isAdmin == 1){
         return true;
        }
        else {
          let status = false;

          if (path == "easyview" && parsedData.MODULE_ACCESS.indexOf(1) > -1) {
            status = true;
          } else if (
            path == "edgar-eye" &&
            parsedData.MODULE_ACCESS.indexOf(2) > -1
          ) {
            status = true;
          } else if (
            path == "filings" &&
            parsedData.MODULE_ACCESS.indexOf(3) > -1
          ) {
            status = true;
          } else if (
            path == "imergix" &&
            parsedData.MODULE_ACCESS.indexOf(4) > -1
          ) {
            status = true;
          } else if (
            path == "library" &&
            parsedData.MODULE_ACCESS.indexOf(5) > -1
          ) {
            status = true;
          } else if (
            path == "marketforms" &&
            parsedData.MODULE_ACCESS.indexOf(6) > -1
          ) {
            status = true;
          } else if (
            path == "xe-compare" &&
            parsedData.MODULE_ACCESS.indexOf(7) > -1
          ) {
            status = true;
          } else if(
            path == "eVox" && 
            parsedData.MODULE_ACCESS.indexOf(8) > -1
          ) {
            status = true;
          } else if (
            this.location.path().includes("/filings") &&
            parsedData.MODULE_ACCESS.indexOf(3) > -1
          ) {
            status = true;
          }
          else if(path == "EA-Connect" && parsedData.MODULE_ACCESS.indexOf(9) > -1) {
            status = true;
          }
          else if(path == "validator" && parsedData.MODULE_ACCESS.indexOf(10) > -1) {
            status = true;
          }
          else if(path == "EA-40" && parsedData.MODULE_ACCESS.indexOf(12) > -1) {
            status = true;
          }
          else if(path == "TSR" && parsedData.MODULE_ACCESS.indexOf(13) > -1) {
            status = true;
          }
          else if(path == "BOIR" && parsedData.MODULE_ACCESS.indexOf(14) > -1) {
            status = true;
          }
          else if(this.location.path().includes("/filings") && parsedData.MODULE_ACCESS.indexOf(3) > -1) {
            status = true;
          }

          if(!status)
            this.router.navigate(["/not-found"]);
                    else
            return true;
        }
      } 
      else {
        this.router.navigate(['/' + baseUrl]);
      }
    } catch (e) {
      this.router.navigate(["/" + baseUrl]);
    }
  }
}
