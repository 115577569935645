import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { API_URLS, API_METHOD } from '../constants';
import { Observable } from 'rxjs';
import { Client, Role, IFeatureResponse } from '../models';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(
    private readonly apiService: ApiService
  ) { }

  public getClients(status : any): Observable<Client[]> {
    return this.apiService.invoke({
      path: API_URLS.client,
      method: API_METHOD.get,
      query: status
    });
  }
  public getRoles(): Observable<Role[]> {
    return this.apiService.invoke({
      path: API_URLS.role,
      method: API_METHOD.get,
    });
  }
  getFeatures(): Observable<IFeatureResponse[]> {
    return this.apiService.invoke({
      path: API_URLS.feature,
      method: API_METHOD.get
    },true);
  }
}
