import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { NUMBER, DEFAULT_PAGE_SIZE } from '../constants';
import { ListQuery,analyticsQurey, auditlogQuery, eaTsrQuery,BOIRFilingQuery, DeletedCompanyListQuery, fileCounterListQuery, entityListQuery, clientListQuery } from '../models';

@Injectable({
  providedIn: 'root',
})
export class RouteHelperService {
  constructor(private readonly router: Router) {}
  /**
   * Update query parmater
   * @param params Query parameters
   */
  updateQueryParams(params): void {
    const currentUrl = this.router.url.split('?')[0];
    this.router.navigate([currentUrl], {
      queryParams: params,
    });
  }
 
  public getListQueryParam(queryParams): ListQuery {
    const page = queryParams.get('page');
    const filter = queryParams.get('filter');
    const sort = queryParams.get('sort');
    const order = queryParams.get('order');
    const ped = queryParams.get('ped');
    const size = queryParams.get('size');
    const client = queryParams.get('client');
    const product = queryParams.get('product');
    const type = queryParams.get('type');
    const ftype = queryParams.get('ftype');
    const fid = queryParams.get('fid');
    const docid = queryParams.get('docId');
    const dtype = queryParams.get('dtype');
    const isProduct = queryParams.get('isProduct');
    const mname = queryParams.get('mname');
    const isAccountRep = queryParams.get('isAccountRep');

    return {
      page: page ? +page : NUMBER.one,
      filter: filter ? filter : '',
      sort: sort ? sort : '',
      order: order ? order : '',
      ped: ped ? ped : '',
      size: size ? size : 10,
      client: client ? client : '',
      product: product ? product : '',
      ftype: ftype ? ftype : '',
      fid: fid ? fid : '',
      docId: docid ? docid : '',
      dtype: dtype ? dtype : '',
      isProduct: isProduct ? isProduct : '',
      mname: mname ? mname : '',
      isAccountRep: isAccountRep ? isAccountRep : ''
    };
  }
  public getUserListQueryParam(queryParams): ListQuery {
    const page = queryParams.get('page');
    const filter = queryParams.get('filter');
    const sort = queryParams.get('sort');
    const order = queryParams.get('order');
    const ped = queryParams.get('ped');
    const size = queryParams.get('size');
    const client = queryParams.get('client');
    const clientfilter = queryParams.get('clientfilter');
    const rolerfilter = queryParams.get('rolefilter');
    const isProduct = queryParams.get('isProduct');
    const productId = queryParams.get('productId');
    //const companyfilter = queryParams.get('companyfilter');
    return {
      page: page ? +page : NUMBER.one,
      filter: filter ? filter : '',
      sort: sort ? sort : '',
      order: order ? order : '',
      ped: ped ? ped : '',
      size: size ? size : DEFAULT_PAGE_SIZE,
      client: client ? client : '',
      clientfilter: clientfilter ? clientfilter : '',
      rolefilter: rolerfilter ? rolerfilter : '',
      isProduct: isProduct ? isProduct : '',
      productId: productId ? productId : '',
      //companyfilter: companyfilter ? companyfilter : '',
    };
  }
  public getEAsyViewListQueryParam(queryParams): ListQuery {
    const page = queryParams.get('page');
    const filter = queryParams.get('filter');
    const count = queryParams.get ('count');
    const sort = queryParams.get('sort');
    const order = queryParams.get('order');
    const ped = queryParams.get('ped');
    const size = queryParams.get('size');
    const client = queryParams.get('client');
    const isProduct = queryParams.get('isProduct');
    const filter_preparer = queryParams.get('filter_preparer');        
    const filter_owner = queryParams.get('filter_owner');           
    const filter_billing_group = queryParams.get('filter_billing_group');   
    const filter_filing_date = queryParams.get('filter_filing_date');     
    const filter_filing_status = queryParams.get('filter_filing_status');   
    const filter_filing_status_test = queryParams.get('filter_filing_status_test');
    const filter_filing_start_date = queryParams.get('filter_filing_start_date');
    const filter_filing_end_date = queryParams.get('filter_filing_end_date');
    const filter_channel = queryParams.get('filter_channel');
    const project_group = queryParams.get('project_group');
    const filter_acc_rep = queryParams.get('filter_acc_rep');
    // const ftype = queryParams.get('ftype');
    // const fid = queryParams.get('fid');

    return {
      page: page ? +page : NUMBER.one,
      filter: filter ? filter : '',
      sort: sort ? sort : '',
      order: order ? order : '',
      ped: ped ? ped : '',
      size: size ? size : 10,
      client: client ? client : '',
      isProduct: isProduct ? isProduct : '',
      filter_preparer: filter_preparer ? filter_preparer : '',        
      filter_owner: filter_owner ? filter_owner : '',           
      filter_billing_group: filter_billing_group ? filter_billing_group : '',   
      filter_filing_date: filter_filing_date ? filter_filing_date : '',     
      filter_filing_status: filter_filing_status ? filter_filing_status : '',   
      filter_filing_status_test: filter_filing_status_test ? filter_filing_status_test : '',
      filter_filing_start_date: filter_filing_start_date ? filter_filing_start_date : '',
      filter_filing_end_date: filter_filing_end_date ? filter_filing_end_date : '',
      filter_channel: filter_channel ? filter_channel : '',
      filter_acc_rep: filter_acc_rep ? filter_acc_rep : '',
      project_group: project_group ? project_group : ''
     
    };
  }

  public getBOIRFilingQueryParam(queryParams): BOIRFilingQuery {
    const page: any = queryParams.get('page');
    const size: any = queryParams.get('size');
    const isReview: any = queryParams.get('isReview');
    const filter_preparer: any = queryParams.get('filter_preparer');
    const filter_owner: any = queryParams.get('filter_owner');
    const filter_billing_group = queryParams.get('filter_billing_group');
    const filter_filing_date = queryParams.get('filter_filing_date');
    const filter_filing_status:any = queryParams.get('filter_filing_status');
    const filter_filing_status_test:any = queryParams.get('filter_filing_status_test');
    const isfiledRepots = queryParams.get('isfiledRepots');
    const docId = queryParams.get("docId");
    const companyId = queryParams.get("companyId");
    return {
      page: page ? +page : 1, // Assuming NUMBER.one is a constant for 1
      size: size ? +size : 10, // Assuming 10 as the default size, convert to a number
      isReview: isReview  ? isReview : false, // Assuming false as the default value
      filter_preparer: filter_preparer ? filter_preparer :'', 
      filter_owner: filter_owner ? filter_owner : '',
      filter_billing_group: filter_billing_group ? filter_billing_group : '',
      filter_filing_date: filter_filing_date ? filter_filing_date : '',
      filter_filing_status: filter_filing_status ? filter_filing_status : '',
      filter_filing_status_test: filter_filing_status_test ? filter_filing_status_test : '',
      isfiledRepots: isfiledRepots ? isfiledRepots : false,
      docId: docId ? docId : "",
      companyId: companyId ? companyId : ""
    };
  }

  public evoxListQueryParam(queryParams): analyticsQurey {
    const filter = queryParams.get('filter');
    const startrange = queryParams.get('startrange');
    const endrange = queryParams.get('endrange');

    return {
      filter: filter ? filter : NUMBER.one,
      startrange: startrange ? startrange : '',
      endrange: endrange ? endrange : '',

    };
  }
  public auditLogQueryParam(queryParams): auditlogQuery {
    const page = queryParams.get('page');
    const actionfilter = queryParams.get('actionfilter');
    const limit = queryParams.get ('limit');
    const type = queryParams.get ('type')
    const search = queryParams.get ('search')
    return {
      page: page ? +page : NUMBER.one,
      actionfilter :actionfilter ? actionfilter : '',
      limit : limit ? limit : DEFAULT_PAGE_SIZE,
      type : type ? type : '',
      search : search ? search : '',
    };
  }

  public getEaTsrQueryParams(queryParams): eaTsrQuery {
    const page = queryParams.get('page');
    const search = queryParams.get('search');
    const size = queryParams.get('size');
    const active = queryParams.get('active');
    const user = queryParams.get('user');
    return {
      page: page ? +page : NUMBER.one,
      search: search ? search : '',
      size: size ? +size : DEFAULT_PAGE_SIZE,
      active: active ? +active : NUMBER.one,
      user: user ? user : ""
    };
  }

  public getDeletedCompanyListQueryParams(queryParams: any): DeletedCompanyListQuery {
    const page = queryParams.get('page');
    const search = queryParams.get('search');
    const size = queryParams.get('size');
    const sort = queryParams.get('sort');
    const filter = queryParams.get('filter');
    const order = queryParams.get('order');

    return {
      page: page ? +page : NUMBER.one,
      search: search ? search : '',
      size: size ? +size : DEFAULT_PAGE_SIZE,
      sort: sort ? sort : 'desc',
      filter: filter ? filter : '',
      order: order ? order : '',
    }
  }

  public getFileCounterQueryParams(queryParams): fileCounterListQuery {
    const page = queryParams.get('page');
    const filter = queryParams.get('filter');
    const size = queryParams.get('size');
    const channel = queryParams.get('channel');
    const status = queryParams.get('status');
    const type = queryParams.get('type');
    const module = queryParams.get('module');
    const company = queryParams.get('company');
    const filterdate = queryParams.get('filterdate');
    const startDate = queryParams.get('startDate');
    const endDate = queryParams.get('endDate');

    return {
      page: page ? +page : NUMBER.one,
      filter: filter ? filter : '',
      size: size ? size : 10,
      channel: channel ? channel : '',
      status: status ? status : NUMBER.one,
      type: type ? type : '',
      module: module ? module : NUMBER.one,
      company: company ? company : '',
      filterdate: filterdate ? filterdate : NUMBER.one,
      startDate: startDate ? startDate : '',
      endDate: endDate ? endDate : '',
    };
  }

  public getentityCompanyListQueryParam(queryParams): entityListQuery {
    const page = queryParams.get('page');
    const filter = queryParams.get('filter');
    const size = queryParams.get('size');
    const entityID = queryParams.get('entityID');
    return {
      page: page ? +page : NUMBER.one,
      filter: filter ? filter : '',
      size: size ? +size : 10,
      entityID: entityID ? +entityID : NUMBER.zero
    };
  }


  public getClientListQueryParam(queryParams): clientListQuery {
    const status = queryParams.get('status');
    return {
      status: status ? +status : NUMBER.one,
    };
  }
}