import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from "@angular/router";
import { ROUTE_PATHS } from "src/app/core/constants";
import { WebsocketService } from "src/app/core/services";
export interface User {
  id: number;
  username: string;
  token: string;
}

@Injectable({
  providedIn: "root",
})
export class UnrestrictedAccessService {
  private currentUserSubject: BehaviorSubject<User | null>;
  public currentUser: Observable<User | null>;

  constructor(private http: HttpClient, public router: Router, private route: ActivatedRoute, private socket: WebsocketService) {
    this.currentUserSubject = new BehaviorSubject<User | null>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  getPdfPreviewData(form_id: any) {
    const formData = {
      form_id: form_id
    }
    const url = `${environment.apiUrl}boirforms/preview/getFormInfoForReview`
    return this.http.post(url, formData, { responseType: 'text' });
  } 

 
  login(username: string, password: string): Observable<User> {
    return this.http.post<any>(`${environment.apiUrl}/login`, { username, password })
      .pipe(map(user => { 
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout(access: any, user: any,whitelabel:any|null = null): void {
    localStorage.removeItem('gus_accessToken');
    localStorage.removeItem('gus_refreshToken');
    localStorage.removeItem('gus_username');
    localStorage.removeItem('gus_email');
    localStorage.removeItem('gus_id');
    localStorage.clear();    
    this.socket.disconnect();
    // let whitelabel: string | null = this.route.snapshot.params['whitelabel'];
    let routepath: string = `/${ROUTE_PATHS.xe_login}`;
    if (whitelabel) {
      routepath += `/${whitelabel}`
    }    
    this.router.navigate([routepath], { queryParams: { user: user, access: access } });
  }

  isAuthenticated(): boolean { 
    return !!this.currentUser;
  }

  updatePreviewedStatus(formData: any) { 
    const url = `${environment.apiUrl}boirforms/preview/updatePreviewedStatus`
    return this.http.post(url, formData);
  }

  getFilingStatus(formData: any) { 
    const url = `${environment.apiUrl}boirforms/preview/getFilingStatus`
    return this.http.post(url, formData);
  }

  /**
   * BOIR BO - Invite
   * @method = GET, POST
   * @purpose = Get Info, Sending Invite, Update Bo Info, Extract Image Data (OCR API)
   */
  public getInvitedUser(): Observable<any> {
    const url: string = `${environment.apiUrl}boirforms/bo-invite/bo-invite-user`;
    return this.http.get(url);
  }

  public updateBOData(data: any): Observable<any> {
    const url: string = `${environment.apiUrl}boirforms/bo-invite/save-BO-invite-user`;
    return this.http.post(url, data);
  }

  public getVerify(formData: FormData): Observable<any> {
    const url: string = `${environment.apiUrl}boirforms/bo-invite/read-image-data`;
    return this.http.post(url, formData);
  }

  public getDependency(option: string): Observable<any> {
    const url: string = `${environment.apiUrl}boirforms/bo-invite/ddowns/${option}`;
    return this.http.get(url);
  }

  public approveAndFile(data: any): Observable<any> {
    const url: string = `${environment.apiUrl}boirforms/preview/approveAndFile`;
    return this.http.post(url, data);
  }
}