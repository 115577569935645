import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CookieService } from "ngx-cookie-service";
import { BehaviorSubject, Observable, of, throwError } from "rxjs";
import { catchError, filter, switchMap, take } from "rxjs/operators";
import { ROUTE_PATHS } from "../constants";
import { UnrestrictedAccessService } from "../../modules/unrestricted-access/unrestricted-access.service";
import { AuthService } from "../../shared/services/auth.service";
@Injectable({
  providedIn: "root",
})
export class HttpConfigInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    false
  );
  public role_flag: any;
  public LogOut_OB: boolean = false;
  constructor(
      private authService: AuthService,
      private router: Router, public route: ActivatedRoute,
      public unrestrictedAccessService: UnrestrictedAccessService,
      public cookieService:CookieService
    )
       { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const accessToken: any = localStorage.getItem("x-access-token");
    const accessToken: any = this.cookieService.get('x-access-token') || localStorage.getItem("gus_accessToken"); 
    req = req.clone({
      headers: req.headers.set("Authorization", `Bearer ${accessToken}`),
    });

    return next.handle(req).pipe(
      catchError((requestError) => {
        if (req.url.includes("/auth/refresh-token")) {
          // this.authService.logout();
          let baseUrl = localStorage.getItem("baseUrl");
          if (baseUrl) {
            this.router.navigateByUrl(baseUrl);
          } else {
            this.router.navigateByUrl(ROUTE_PATHS.xe_login);
          }
        } else if (requestError.status == 401) {
          if (req.url.includes("/session/login")) {
            return throwError(requestError);
          } 
          if (req.url.includes("/boirforms/preview") || req.url.includes("/stripe/preview")) {
            console.log(this.route.snapshot.queryParams, "req------------------------------------", req);
            let access = this.route.snapshot.queryParams['access'];
            let user = this.route.snapshot.queryParams['user'];
            if (access && user) {
              this.unrestrictedAccessService.logout(access, user);
            }
          } 
          else {
            return this.handle401Error(req, next);
          }
        } else {
          return throwError(requestError);
        }
      })
    );
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter((result) => result !== null),
        take(1),
        switchMap((jwt: any) => {
          // console.log("jwt token...", jwt);
          if (!jwt || !jwt.token) {
            console.error("JWT is undefined or has no token");
            return throwError(new Error("Invalid JWT token"));
          }
          return next.handle(this.addAuthenticationToken(req, jwt.token));
        })
      );
    } else {
      this.refreshTokenInProgress = true;
      this.refreshTokenSubject.next(null);
      return this.authService.refreshToken().pipe(
        switchMap((retrieveNewToken: any) => {
          this.refreshTokenInProgress = false;
          if (!retrieveNewToken || !retrieveNewToken.access_token) {
            // console.error("Retrieved token is undefined or has no token");
            return throwError(new Error("Invalid retrieved token"));
          }
          this.refreshTokenSubject.next(retrieveNewToken.access_token);
          return next.handle(this.addAuthenticationToken(req, retrieveNewToken.access_token));
        }),
        catchError((err) => {
          console.error("Error during token refresh", err);
          this.refreshTokenInProgress = false;
          this.authService.logout();
          let baseUrl = localStorage.getItem("baseUrl");
          // console.log("baseUrl...", baseUrl);
          if (baseUrl) {
            this.router.navigateByUrl(baseUrl);
          } else {
            this.router.navigateByUrl(ROUTE_PATHS.xe_login);
          }
          return of(err);
        })
      );
    }
  }

  addAuthenticationToken(
    request: HttpRequest<any>,
    token: any
  ): HttpRequest<any> {
    // const sessionToken: any = localStorage.getItem("x-access-token");
    const sessionToken: any = this.cookieService.get('x-access-token');
    request.clone({
      headers: request.headers.set("Content-Type", "application/json"),
    });
    request.clone({
      headers: request.headers.set("Accept", "*/*"),
    });
    return request.clone({
      headers: request.headers.set("Authorization", `Bearer ${sessionToken}`),
    });
  }
}
