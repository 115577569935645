import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { ROUTE_PATHS } from '../constants';
import { IUserConfig } from '../models/user-config.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LoginHelperService } from 'src/app/core/services/login-helper.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public userConfig: IUserConfig;
  public isOnlyReviewer = false;
  constructor(
    private readonly apiService: ApiService,
    public http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private loginHelperService: LoginHelperService
  ) { }

  getUserConfiguration() {
    return this.cookieService.get('userConfigList') ? JSON.parse(this.cookieService.get('userConfigList')) : null;
  }

  setUserConfig(userConfig: any) {
    this.userConfig = JSON.parse(userConfig);
  }

  getUserInformation(apiUrl: any, apiData: any) {
    return this.http.post(`${environment.apiUrl}${apiUrl}`, apiData);
  }
  putUserInformation(apiUrl: any, apiData: any) {
    return this.http.put(`${environment.apiUrl}${apiUrl}`, apiData);
  }


  currentUserConfig(): IUserConfig {
    let userConfigList = this.cookieService.get('userConfigList');
    if (userConfigList) {
      this.userConfig = JSON.parse(userConfigList);
      return this.userConfig;
    } else {
      return null;
    }
  }

  setIsOnlyReviewer(value){
    this.isOnlyReviewer = value;
  }

  redirectToHome(userConfig: any): void {
    let configList = userConfig;
    let userId: any = localStorage.getItem('userDetailsData') || localStorage.getItem("userId") || localStorage.getItem("UserId");
    let roles: string[] = configList.UserRoles.map((list: any) => list.RoleName);
    let isReviewer:Boolean = roles.includes("Reviewer") && roles.length == 1 ? true :false;
    let isBOIRUploader:Boolean = roles.includes("BOIR Uploader") && roles.length == 1 ? true :false;
    let url: string = `/${ROUTE_PATHS.BOIR}`;
    if (userId != 1) {
      if(configList.MODULE_ACCESS.length == 0)
        url = `/not-found`;
      else if(configList.singleCompany && "CompanyID" in configList.singleCompany) {
        url = configList.MODULE_ACCESS.length > 0  ? `/${ROUTE_PATHS.BOIR}` : `/not-found`;
        if(isReviewer) {
          this.isOnlyReviewer = true;
          url = configList.MODULE_ACCESS.length > 0  ? `/${ROUTE_PATHS.BOIR}` : `/not-found`;
        }
        else if(isBOIRUploader) {
          url = configList.MODULE_ACCESS.length > 0  ? `/${ROUTE_PATHS.BOIR}/${ROUTE_PATHS.DOCUMENTS}` : `/not-found`;
        }
      }
      else {
        if(isReviewer) {
          this.isOnlyReviewer = false;
          url = configList.MODULE_ACCESS.length > 0  ? `/${ROUTE_PATHS.BOIR}` : `/not-found`;
        }
        if(isBOIRUploader) {
          url = configList.MODULE_ACCESS.length > 0  ? `/${ROUTE_PATHS.BOIR}` : `/not-found`;
        }
      }
    }

    if (url != "/not-found") {
      let whitelabel: string | null = this.loginHelperService.getWhiteLabelInfo();
      url = (whitelabel ? `/${whitelabel}` : "") + url;
    }
    console.log(url, "url")
    this.router.navigateByUrl(url);
  }

  checkWhiteLabel(apiData: any): Observable<any> {
    let apiUrl: string = environment.apiUrl + "auth/check-whitelabel";
    return this.http.post(apiUrl, apiData);
  }
  getTourStatus(apiUrl: any, apiData: any) {
    return this.http.post(`${environment.apiUrl}${apiUrl}`, apiData);
  }
  updateTourStatus(userID: any, tourSettings: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}boirforms/updateTour`;
    return this.http.post(apiUrl, { userID, ...tourSettings });
  }
}
