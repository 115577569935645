import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {  catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
const rootUrl = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private http: HttpClient) { }

  public getApiCall(url: string) {
    const uri = `${rootUrl}${url}`;
    return this.http.get<any>(uri)
      .pipe(catchError(this.errroHandler))
  }

  public postApiCall(apiData: any, url: string) {
    const uri = `${rootUrl}${url}`;
    return this.http.post<any>(uri, apiData)
      .pipe(catchError(this.errroHandler))
  }
  public patch(apiData: any, url: string) {
    const uri = `${rootUrl}${url}`;
    return this.http.patch<any>(uri, apiData)
      .pipe(catchError(this.errroHandler))
  }

  public patchApiCall(apiData: any, url: string) {
    const uri = `${rootUrl}${url}`;
    return this.http.patch<any>(uri, apiData)
      .pipe(catchError(this.errroHandler))
  }

  public contactSupport(apiData: object) {
    const uri = `${rootUrl}api/contact-support`;
    return this.http.post(uri, apiData)
  }

  public errroHandler(error: HttpErrorResponse) {
    return throwError(error);
  }
}
