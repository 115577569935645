import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginHelperService {

  public channel = new BroadcastChannel("app_channel");
  constructor() { }

  setWhiteLabelInfo(url: string): void {
    localStorage.setItem("url", window.btoa(url));
  }

  getWhiteLabelInfo(): string | null | undefined {
    let whiteLabel = localStorage.getItem("url");
    return whiteLabel ? window.atob(whiteLabel) : null;
  }

  removeWhiteLabel(): void {
    localStorage.removeItem("url");
  }

  sendMessage(message: string) {
    this.channel.postMessage(message);
  }
}
