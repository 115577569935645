import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ROUTE_PATHS } from "./core/constants";
import { AuthGuard } from "./core/guards/auth.guard";
import { UserManagementGuard } from "./core/guards/user-management.guard";
import { PrivacyPolicyComponent } from "./modules/privacy-policy/privacy-policy.component";

const routes: Routes = [
  {
path: ROUTE_PATHS.PrivacyPolicy,
component: PrivacyPolicyComponent
  },
  {
    path: ROUTE_PATHS.ROOT,
    redirectTo: ROUTE_PATHS.xe_login,
    pathMatch: "full",
  },
  {
    path: `:whitelabel/${ROUTE_PATHS.dashboard}`,
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: ROUTE_PATHS.dashboard,
    loadChildren: () =>
      import("./modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: ROUTE_PATHS.USERS,
    loadChildren: () =>
      import("./modules/user/user.module").then((m) => m.UserModule),
    canActivate: [UserManagementGuard],
  },
  {
    path: `:whitelabel/${ROUTE_PATHS.USERS}`,
    loadChildren: () =>
      import("./modules/user/user.module").then((m) => m.UserModule),
    canActivate: [UserManagementGuard],
  },
  {
    path: `:whitelabel/${ROUTE_PATHS.client}`,
    loadChildren: () => 
      import("./modules/client-management/client-management.module").then(
        (m) => m.ClientManagementModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.client,
    loadChildren: () =>
      import("./modules/client-management/client-management.module").then(
        (m) => m.ClientManagementModule
      ),
    canLoad: [AuthGuard],
  },
  {
    path: ROUTE_PATHS.xe_login,
    loadChildren: () =>
      import("./modules/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: ROUTE_PATHS.xe_reset_password,
    loadChildren: () =>
      import("./modules/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
  },
  {
    path: ROUTE_PATHS.setUserPassword,
    loadChildren: () =>
      import("./modules/set-user-password/set-user-password.module").then(
        (m) => m.SetUserPasswordModule
      ),
  },
  {
    path:`:whitelabel/${ROUTE_PATHS.setUserPassword}`,
    loadChildren: () =>
      import("./modules/set-user-password/set-user-password.module").then(
        (m) => m.SetUserPasswordModule
      ),
  },
  {
    path: "not-found",
    loadChildren: () =>
      import("./modules/not-found/not-found.module").then(
        (m) => m.NotFoundModule
      ),
  },
  {
    path: ROUTE_PATHS.xe_setupauthenticator,
    loadChildren: () =>
      import("./modules/setup-authenticator/setup-authenticator.module").then(
        (m) => m.SetupAuthenticatorModule
      ),
  },
  {
    path:ROUTE_PATHS.BOIR,
    loadChildren: () =>
    import('./modules/boir/boir.module').then((m) => m.BoirModule)
  },
  {
    path:`:whitelabel/${ROUTE_PATHS.BOIR}`,
    loadChildren: () =>
    import('./modules/boir/boir.module').then((m) => m.BoirModule)
  },
  {
    path: ROUTE_PATHS.common,
    loadChildren: () =>
      import('./modules/unrestricted-access/unrestricted-access.module').then((m) => m.UnrestrictedAccessModule),
  },
  {
    path: `:whitelabel/${ROUTE_PATHS.common}`,
    loadChildren: () =>
      import('./modules/unrestricted-access/unrestricted-access.module').then((m) => m.UnrestrictedAccessModule),
  },
  {
    path: ROUTE_PATHS.UserGuide,
    loadChildren: () =>
      import('./modules/tour-guide/tour-guide.module').then((m) => m.TourGuideModule),
  },
  {
    path: `:whitelabel/${ROUTE_PATHS.UserGuide}`,
    loadChildren: () =>
      import('./modules/tour-guide/tour-guide.module').then((m) => m.TourGuideModule),
  },
  {
    path: ROUTE_PATHS.replaceUserGuide,
    loadChildren: () =>
      import('./modules/tour-guide/tour-guide.module').then((m) => m.TourGuideModule),
  },
  { path: "**", redirectTo: ROUTE_PATHS.ROOT },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: "reload" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
