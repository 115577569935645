import { Injectable } from "@angular/core";
import { io, Socket } from "socket.io-client";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root"
})
export class WebsocketService {
  private socket: Socket;
  constructor(private cookieService: CookieService ) {
    // this.initialize();
  }

  public initialize() {
    const token =  this.cookieService.get('x-access-token');
    this.socket = io(`${environment.socketURL}notification`, {
      "transports": ["polling", "websocket"],
      // "forceNew": true,
      "upgrade": true,
      "autoConnect": false,
      // "reconnection": true,
      // "reconnectionDelay": 1000,
      "transportOptions": {
        "websocket": {
          "extraHeaders": { 
            Authorization: token,
          }    
        }
      },
      "rememberUpgrade": true,
      "extraHeaders": { 
        Authorization: token,
      }
    });
    this.socket.connect();
  }

  getSocket() {
    console.log(this.socket);
    if (!this.socket) { 
      this.initialize();
      return this.getSocket();
    }
    // else if (this.socket && !this.socket.connected) {
    //   this.socket = undefined;
    //   const token =  this.cookieService.get('x-access-token');
    //   this.socket = io(`${environment.socketURL}notification`, {
    //     "transports": ["polling", "websocket"],
    //     "upgrade": true,
    //     "autoConnect": false,
    //     "transportOptions": {
    //       "websocket": {
    //         "extraHeaders": { 
    //           Authorization: token,
    //         }    
    //       }
    //     },
    //     "rememberUpgrade": true,
    //     "extraHeaders": { 
    //       Authorization: token,
    //     }
    //   });        
    //   this.socket.connect();
    //   return this.socket;
    // }
    else return this.socket;
  }

  notificationRequest(eventName: string | null = null, data: any = null): void {
    if (!eventName)
      this.socket.emit("notification_request", "");
    else
      this.socket.emit(eventName, data);
  }

  notificationResponse(): Observable<any> {
    return new Observable<any>((observer) => {
      this.socket.on("notification_alert", (data: any) => {
        observer.next(data);
      });
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
    console.log(this.socket);
  }

}