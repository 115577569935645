import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from "src/app/shared/services/auth.service";
// import { ROUTE_PATHS } from "../core/constants/route.constant";

  @Injectable({
    providedIn: 'root'
  })
  export class VersionCheckService {
    private currentVersion:any;
    newVersion = '';
    fileContent: string = '';
    client: any;
    user_id: any;
    constructor(private http: HttpClient,private authservice: AuthService,
      private cookieService: CookieService,private readonly router: Router
    ) { this.client = localStorage.getItem("baseUrl");
  }

    ngOnInit() {
    }

    public initVersionCheck(url: string) {
      this.checkVersion(url).catch(err => {
        console.error('Error during version check:', err);
      });
      // interval(1000 * 60 * 1).subscribe(() => {
      //   this.checkVersion(url).catch(err => {
      //     console.error('Error during periodic version check:', err);
      //   });
      // });
    }
    private async checkVersion(url: string): Promise<void> {
      try {
        const response: any = await this.http.get(url).toPromise();
        this.newVersion = response.version;
        let oldversion: string | number | null = localStorage.getItem("version");
        let newversion: string | number | null = this.newVersion;
 
    if (oldversion)
        oldversion = Number(oldversion.replace(/\D/g, ''));
    if (newversion)
        newversion = Number(newversion.replace(/\D/g, ''));
 
    if (!oldversion)
        localStorage.setItem('version', this.newVersion?.toString());
    else if (oldversion < newversion)
      this.promptUser();
      
      } catch (error) {
        console.error('Error fetching version:', error);
      }
    
  }

    promptUser() {
      const userConfirmed = confirm(`A new version (${this.newVersion}) of the application is available. Click "OK" to load new version.`);
      if (userConfirmed) {
         this.clearcookiesandNegaviteLogin();
         localStorage.setItem('version',this.newVersion);
        // localStorage.setItem(this.APP_VERSION_KEY, this.newVersion);
      }
    }

    clearcookiesandNegaviteLogin() {
          this.deleteAllCookies();
          localStorage.clear();
          sessionStorage.clear();
          this.authservice.logout();
    }

    deleteAllCookies() {
      const allCookies = this.cookieService.getAll();
    Object.keys(allCookies).forEach(cookieName => {
        this.cookieService.delete(cookieName, '/');
    });
    }
  };

