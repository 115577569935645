export const API_URLS = {
  apiPrefix: "api",
  ditaPrefix: "dita",
  history: "history",
  historyList: "doc-history/insert-document-history",
  company: "company",
  companylist: "companylist",
  document: "document",
  validationReport:'validationReport',
  versionvalidate: "validateversion",
  filingDocumentList: "document-filing",
  eaconnect:"eaconnect",
  user: "user",
  mfa: "mfa",
  client: "client",
  role: "role",
  ditaConfig: "config",
  formType: "formType",
  formTypeByFilter: "formTypeByFilter",
  sub_formtype: "8k_sub_formtypes",
  documentDitaDetail: "eddetails",
  status: "status",
  migrate: "migrate",
  documentEditorProp: "editor/prop",
  previousVersion: "ver/prev",
  xppPublish: "xpp/publish",
  xppPublishBlackline: "xpp/publish/blackline",
  xppExportGenerate: "xpp/export/generate",
  xppExportHtml: "xpp/export/html",
  xppExportIxHtml: "xpp/export/ixhtml",
  xppExportXBRL: "xpp/export/ixbrl",
  xppExportWord: "xpp/export/word",
  release: "releases",
  compare: "compare",
  userConfig: "userconfig",
  draftable: "draftable",
  export: "export",
  exportSingle: "single_page",
  combined: "combined",
  feature: "feature",
  query: "query",
  lock: "lock",
  folder: "folder",
  ditamap: "ditamap",
  ditatree: "ditatree",
  oktaActivationEmail: "okta-activation-email",
  rollforward: "rollforward",
  html2dita: "html2dita",
  file: "file",
  xbrl: "xbrl",
  render: "render",
  docId: "docId",
  metadata: "metadata",
  formdescription: "formdescription",
  filings: "filings",
  filingList: "filings/get-filings",
  addfiling: "filings/add-filing",
  updatefiling: "filings/update-filing",
  removefiling: "filings/remove-filing",
  publishFiling: "filings/publish",
  rollForward: "filings/roll-forward",
  releaseFiling: "filings/release",
  releaseFilingFinal: "filings/final-release",
  getClientInfo: "submission-header/get-client-details",
  srosDetails: "submission-header/get-sros-list",
  addSubmissionHeader: "submission-header/add-submission-header",
  getExistingHeaderInfo: "submission-header/get-submission-header",
  getSubmissionHeader: "submission-header/get-submission-header",
  getUserAlerts: "edgar-eye/alerts/user",
  getGeneratedAlerts: "edgar-eye/alerts/generated",
  addAlert: "edgar-eye/alerts/add",
  updateAlert: "edgar-eye/alerts/update",
  deleteAlert: "edgar-eye/alerts/delete",
  markAlertSeen: "edgar-eye/alerts/seen",
  getFormTypes: "edgar-eye/alerts/forms",
  getTodaysFilings: "edgar-eye/todays-filings",
  getFilingsForCik: "edgar-eye/filings",
  downloadFiling: "edgar-eye/download-filing",
  cikLookup: "edgar-eye/cik-lookup",
  updateSubmissionHeader: "submission-header/update-submission-header",
  getNotificationMailList: "submission-header/get-notification-emails",
  getJpmStyleList: "common/jpm/get-jpm-style-list",
  styleValidate: "word/validate-style",
  generateExhibit: "filings/generate-exhibit",
  renameFolderFileInJCR: "folder/rename-folder-file",
  downloadPublishedNonXbrl: "download/get-nonxbrl-file",
  getDitaMapInfo: 'filings/document-builder/get-dita-info',
  getDitaMapList: 'filings/document-builder/get-ditamap-list',  
  editor: "editor",
  content: "content",
  moduleList: 'module',
  getDocument:'get-document',
  auth: "auth",	
  checkUserStatus: "check-user-status",
  usersList: 'editor/author-list',
  addComment:'add-comment',
  comment:'comment',
  updateDocument:'update-document', 
  analaytics: 'analytics/evox',
  auditlog: 'analytics/audit-log',
  variable: "variable",
  trackChanges: "track-changes",
  getTitles: "gettitles",
  deletedCompanies: "deleted-companies",
  restoreCompany: "restore-company" ,
  unlock: "unlock",
  unlockuser: "unlock-user",
  ditaRelease: "release",
  details: "details",
  allcompany: "allcompany",
  updateStatus : "updateStatus",
};

export const BACKEND_URLS = {
  content: "content",
  editor: "editor",
  pdfDownloadUrl: "xpp/export/pdf",
  downloadPath: "download/path",
  documentDownload: "document/download",
};
